@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --black: rgba(33, 33, 33, 1);
  --drop-shadow: 0px 1.5px 15px 0px rgba(0, 68, 69, 0.18);
  --variable-collection-background-white: rgba(255, 255, 255, 1);
  --variable-collection-main-emerald: rgba(16, 185, 129, 1);
  --variable-collection-main-lightgreen: rgba(16, 185, 129, 1);
  --variable-collection-main-subtext: rgba(144, 144, 144, 1);
  --variable-collection-main-teal: rgba(45, 121, 109, 1);
  --variable-collection-stroke: rgba(220, 226, 239, 1);
  --variable-collection-stroke-input: rgba(220, 226, 239, 1);
}
