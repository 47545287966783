.all-\[unset\] {
  all: unset;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-10 {
  left: 2.5rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-4 {
  left: 1rem;
}

.left-7 {
  left: 1.75rem;
}

.left-\[114px\] {
  left: 114px;
}

.left-\[124px\] {
  left: 124px;
}

.left-\[131px\] {
  left: 131px;
}

.left-\[133px\] {
  left: 133px;
}

.left-\[157px\] {
  left: 157px;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[17px\] {
  left: 17px;
}

.left-\[182px\] {
  left: 182px;
}

.left-\[186px\] {
  left: 186px;
}

.left-\[18px\] {
  left: 18px;
}

.left-\[191px\] {
  left: 191px;
}

.left-\[209px\] {
  left: 209px;
}

.left-\[218px\] {
  left: 218px;
}

.left-\[21px\] {
  left: 21px;
}

.left-\[239px\] {
  left: 239px;
}

.left-\[254px\] {
  left: 254px;
}

.left-\[25px\] {
  left: 25px;
}

.left-\[26px\] {
  left: 26px;
}

.left-\[27px\] {
  left: 27px;
}

.left-\[337px\] {
  left: 337px;
}

.left-\[355px\] {
  left: 355px;
}

.left-\[390px\] {
  left: 390px;
}

.left-\[39px\] {
  left: 39px;
}

.left-\[443px\] {
  left: 443px;
}

.left-\[452px\] {
  left: 452px;
}

.left-\[478px\] {
  left: 478px;
}

.left-\[537px\] {
  left: 537px;
}

.left-\[560px\] {
  left: 560px;
}

.left-\[63px\] {
  left: 63px;
}

.left-\[68px\] {
  left: 68px;
}

.left-\[69px\] {
  left: 69px;
}

.left-\[75px\] {
  left: 75px;
}

.top-0 {
  top: 0;
}

.top-16 {
  top: 4rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-5 {
  top: 1.25rem;
}

.top-7 {
  top: 1.75rem;
}

.top-\[1047px\] {
  top: 1047px;
}

.top-\[106px\] {
  top: 106px;
}

.top-\[107px\] {
  top: 107px;
}

.top-\[1119px\] {
  top: 1119px;
}

.top-\[117px\] {
  top: 117px;
}

.top-\[123px\] {
  top: 123px;
}

.top-\[127px\] {
  top: 127px;
}

.top-\[130px\] {
  top: 130px;
}

.top-\[131px\] {
  top: 131px;
}

.top-\[1356px\] {
  top: 1356px;
}

.top-\[136px\] {
  top: 136px;
}

.top-\[1456px\] {
  top: 1456px;
}

.top-\[1508px\] {
  top: 1508px;
}

.top-\[152px\] {
  top: 152px;
}

.top-\[157px\] {
  top: 157px;
}

.top-\[15px\] {
  top: 15px;
}

.top-\[1604px\] {
  top: 1604px;
}

.top-\[1816px\] {
  top: 1816px;
}

.top-\[184px\] {
  top: 184px;
}

.top-\[186px\] {
  top: 186px;
}

.top-\[1888px\] {
  top: 1888px;
}

.top-\[2053px\] {
  top: 2053px;
}

.top-\[215px\] {
  top: 215px;
}

.top-\[219px\] {
  top: 219px;
}

.top-\[21px\] {
  top: 21px;
}

.top-\[227px\] {
  top: 227px;
}

.top-\[229px\] {
  top: 229px;
}

.top-\[22px\] {
  top: 22px;
}

.top-\[266px\] {
  top: 266px;
}

.top-\[2701px\] {
  top: 2701px;
}

.top-\[299px\] {
  top: 299px;
}

.top-\[3269px\] {
  top: 3269px;
}

.top-\[342px\] {
  top: 342px;
}

.top-\[352px\] {
  top: 352px;
}

.top-\[35px\] {
  top: 35px;
}

.top-\[364px\] {
  top: 364px;
}

.top-\[39px\] {
  top: 39px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[583px\] {
  top: 583px;
}

.top-\[58px\] {
  top: 58px;
}

.top-\[610px\] {
  top: 610px;
}

.top-\[70px\] {
  top: 70px;
}

.top-\[78px\] {
  top: 78px;
}

.top-\[841px\] {
  top: 841px;
}

.top-\[92px\] {
  top: 92px;
}

.top-\[981px\] {
  top: 981px;
}

.mb-\[-17\.00px\] {
  margin-bottom: -17px;
}

.mb-\[-33\.00px\] {
  margin-bottom: -33px;
}

.ml-\[-1\.50px\] {
  margin-left: -1.5px;
}

.mr-\[-2\.00px\] {
  margin-right: -2px;
}

.mr-\[-31\.00px\] {
  margin-right: -31px;
}

.mr-\[-39\.00px\] {
  margin-right: -39px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-1\.50px\] {
  margin-top: -1.5px;
}

.box-border {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-11 {
  height: 2.75rem;
}

.h-36 {
  height: 9rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[110px\] {
  height: 110px;
}

.h-\[122px\] {
  height: 122px;
}

.h-\[127px\] {
  height: 127px;
}

.h-\[131px\] {
  height: 131px;
}

.h-\[163px\] {
  height: 163px;
}

.h-\[183px\] {
  height: 183px;
}

.h-\[185px\] {
  height: 185px;
}

.h-\[1975px\] {
  height: 1975px;
}

.h-\[209px\] {
  height: 209px;
}

.h-\[222px\] {
  height: 222px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[242px\] {
  height: 242px;
}

.h-\[248px\] {
  height: 248px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[29px\] {
  height: 29px;
}

.h-\[30\.5px\] {
  height: 30.5px;
}

.h-\[30\.77px\] {
  height: 30.77px;
}

.h-\[304px\] {
  height: 304px;
}

.h-\[324px\] {
  height: 324px;
}

.h-\[338px\] {
  height: 338px;
}

.h-\[3593px\] {
  height: 3593px;
}

.h-\[362px\] {
  height: 362px;
}

.h-\[372px\] {
  height: 372px;
}

.h-\[390px\] {
  height: 390px;
}

.h-\[39px\] {
  height: 39px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[43px\] {
  height: 43px;
}

.h-\[467px\] {
  height: 467px;
}

.h-\[471px\] {
  height: 471px;
}

.h-\[521px\] {
  height: 521px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[59px\] {
  height: 59px;
}

.h-\[601px\] {
  height: 601px;
}

.h-\[62px\] {
  height: 62px;
}

.h-\[65\.68px\] {
  height: 65.68px;
}

.h-\[71px\] {
  height: 71px;
}

.h-\[81px\] {
  height: 81px;
}

.h-\[93px\] {
  height: 93px;
}

.w-9 {
  width: 2.25rem;
}

.w-\[101px\] {
  width: 101px;
}

.w-\[1080px\] {
  width: 1080px;
}

.w-\[108px\] {
  width: 108px;
}

.w-\[116px\] {
  width: 116px;
}

.w-\[119px\] {
  width: 119px;
}

.w-\[122px\] {
  width: 122px;
}

.w-\[124px\] {
  width: 124px;
}

.w-\[126px\] {
  width: 126px;
}

.w-\[142px\] {
  width: 142px;
}

.w-\[147px\] {
  width: 147px;
}

.w-\[157px\] {
  width: 157px;
}

.w-\[166px\] {
  width: 166px;
}

.w-\[196px\] {
  width: 196px;
}

.w-\[199px\] {
  width: 199px;
}

.w-\[202px\] {
  width: 202px;
}

.w-\[204px\] {
  width: 204px;
}

.w-\[210px\] {
  width: 210px;
}

.w-\[221px\] {
  width: 221px;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[254px\] {
  width: 254px;
}

.w-\[271px\] {
  width: 271px;
}

.w-\[27px\] {
  width: 27px;
}

.w-\[281px\] {
  width: 281px;
}

.w-\[310px\] {
  width: 310px;
}

.w-\[348px\] {
  width: 348px;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[35\.5px\] {
  width: 35.5px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[363px\] {
  width: 363px;
}

.w-\[364px\] {
  width: 364px;
}

.w-\[369px\] {
  width: 369px;
}

.w-\[396px\] {
  width: 396px;
}

.w-\[429px\] {
  width: 429px;
}

.w-\[43px\] {
  width: 43px;
}

.w-\[445px\] {
  width: 445px;
}

.w-\[452px\] {
  width: 452px;
}

.w-\[456px\] {
  width: 456px;
}

.w-\[49px\] {
  width: 49px;
}

.w-\[518px\] {
  width: 518px;
}

.w-\[52px\] {
  width: 52px;
}

.w-\[561px\] {
  width: 561px;
}

.w-\[575px\] {
  width: 575px;
}

.w-\[59px\] {
  width: 59px;
}

.w-\[61px\] {
  width: 61px;
}

.w-\[657px\] {
  width: 657px;
}

.w-\[79px\] {
  width: 79px;
}

.w-\[853px\] {
  width: 853px;
}

.w-\[85px\] {
  width: 85px;
}

.w-\[888px\] {
  width: 888px;
}

.w-\[897px\] {
  width: 897px;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[942px\] {
  width: 942px;
}

.w-\[966px\] {
  width: 966px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-\[21px\] {
  gap: 21px;
}

.gap-\[29px\] {
  gap: 29px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-transparent {
  border-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\/static\/img\/content-1\.png\)\] {
  background-image: url("content-1.610ecbb4.png");
}

.bg-\[url\(\/static\/img\/image-1000\.png\)\] {
  background-image: url("image-1000.453f041b.png");
}

.bg-\[url\(\/static\/img\/image-2-1\.png\)\] {
  background-image: url("image-2-1.3b80b4aa.png");
}

.bg-\[url\(\/static\/img\/image-2-3\.png\)\] {
  background-image: url("image-2-3.ca3e9470.png");
}

.bg-cover {
  background-size: cover;
}

.bg-\[100\%_100\%\] {
  background-position: 100% 100%;
}

.bg-\[50\%_50\%\] {
  background-position: 50%;
}

.object-cover {
  object-fit: cover;
}

.px-\[53px\] {
  padding-left: 53px;
  padding-right: 53px;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.pb-\[43px\] {
  padding-bottom: 43px;
}

.pl-\[22px\] {
  padding-left: 22px;
}

.pr-\[50px\] {
  padding-right: 50px;
}

.pt-\[19px\] {
  padding-top: 19px;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-\[11\.5px\] {
  line-height: 11.5px;
}

.leading-\[16\.0px\] {
  line-height: 16px;
}

.leading-\[18\.6px\] {
  line-height: 18.6px;
}

.leading-\[20\.0px\] {
  line-height: 20px;
}

.leading-\[22\.4px\] {
  line-height: 22.4px;
}

.leading-\[23\.0px\] {
  line-height: 23px;
}

.leading-\[26\.6px\] {
  line-height: 26.6px;
}

.leading-\[27\.2px\] {
  line-height: 27.2px;
}

.leading-\[28\.8px\] {
  line-height: 28.8px;
}

.leading-\[32\.0px\] {
  line-height: 32px;
}

.leading-\[34\.6px\] {
  line-height: 34.6px;
}

.leading-\[39\.1px\] {
  line-height: 39.1px;
}

.leading-\[40\.0px\] {
  line-height: 40px;
}

.leading-\[normal\] {
  line-height: normal;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.text-\[\#000000\] {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-\[\#2f2f2f\] {
  --tw-text-opacity: 1;
  color: rgb(47 47 47 / var(--tw-text-opacity));
}

.text-\[\#4d4d4d\] {
  --tw-text-opacity: 1;
  color: rgb(77 77 77 / var(--tw-text-opacity));
}

.text-\[\#828282\] {
  --tw-text-opacity: 1;
  color: rgb(130 130 130 / var(--tw-text-opacity));
}

.text-\[\#969696\] {
  --tw-text-opacity: 1;
  color: rgb(150 150 150 / var(--tw-text-opacity));
}

.shadow-\[0px_27px_57px_\#a3a3a340\] {
  --tw-shadow: 0px 27px 57px #a3a3a340;
  --tw-shadow-colored: 0px 27px 57px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_28px_57px_\#bebebe40\] {
  --tw-shadow: 0px 28px 57px #bebebe40;
  --tw-shadow-colored: 0px 28px 57px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[background\:linear-gradient\(180deg\,rgb\(255\,255\,255\)_0\%\,rgb\(245\,246\,249\)_100\%\)\] {
  background: linear-gradient(#fff 0%, #f5f6f9 100%);
}

.\[background\:linear-gradient\(180deg\,rgb\(255\,255\,255\)_0\%\,rgb\(247\,249\,251\)_100\%\)\] {
  background: linear-gradient(#fff 0%, #f7f9fb 100%);
}

.\[border-image\:linear-gradient\(to_bottom\,rgb\(234\.81\,234\.81\,234\.81\)\,rgb\(255\,255\,255\)\)_1\] {
  border-image: linear-gradient(#ebebeb, #fff) 1;
}

.\[font-family\:\'Jost\'\,Helvetica\] {
  font-family: Jost, Helvetica;
}

.\[font-family\:\'Open_Sans\'\,Helvetica\] {
  font-family: Open Sans, Helvetica;
}

:root {
  --black: #212121;
  --drop-shadow: 0px 1.5px 15px 0px #0044452e;
  --variable-collection-background-white: #fff;
  --variable-collection-main-emerald: #10b981;
  --variable-collection-main-lightgreen: #10b981;
  --variable-collection-main-subtext: #909090;
  --variable-collection-main-teal: #2d796d;
  --variable-collection-stroke: #dce2ef;
  --variable-collection-stroke-input: #dce2ef;
}
/*# sourceMappingURL=index.e4875c2a.css.map */
